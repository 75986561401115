:global {
  .container {
    margin-left: auto;
    margin-right: auto;
    padding: calc(#{$gutter} / 2);
  }
  .table-responsive {
    border-radius: 0.25rem;
    overflow-x: auto;
    width: 100%;
  }
  .table {
    border-collapse: collapse;
    width: 100%;
    tbody tr:first-of-type td { border-width: 0px; }
    thead th {
      color: $white;
      background-color: $black;
      border-bottom: 2px solid $black;
    }
    th, td {
      border-top: 1px solid $gray;
      padding: 0.75rem;
    }
  }
  .navbar {
    flex-shrink: 0;
    min-height: 100px;
    .container {
      padding-bottom: 5px;
      padding-top: 5px;
      img {
        max-height: 90px;
        max-width: 100%;
      }
    }
  }
}
