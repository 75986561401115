:global {
  @include linkColor($link, $link-hover);
  .btn {
    color: $white;
    display: inline-block;
    padding: 6px 50px;
    border-radius: $btn-border-radius;
    &:active, &:focus, &:hover { color: $white; }
  }
  .btn-link {
    border: none;
    padding: 0;
  }
  .bg-dark {
    @include linkColor($dark-link, $dark-link-hover);
  }
  .btn-group {
    display: inline-flex;
    vertical-align: middle;
    .btn { flex: 1 1 auto; }
    > .btn:not(:first-child) { margin-left: -1px; }
    > .btn:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    > .btn:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  .btn-sm {
    font-size: 0.875rem;
    padding: 0.25rem 0.5rem;
  }
  .notification {
    &.bg-green-100 {
      @include iconColor(theme("colors.green.700"), $link-hover);
    }
    &.bg-red-100 {
      @include iconColor(theme("colors.red.700"), $link-hover);
    }
  }
}
