@mixin customColor($name, $value) {
  :global {
    .bg-#{$name} { background-color: $value; }
    .border-#{$name} { border-color: $value; }
    .btn-#{$name} {
      background: $value;
      &:active, &:focus, &:hover { background: darken($value, 10%); }
    }
    .btn-outline-#{$name} {
      border: 1px solid $value;
      color: $value;
      &:active, &:focus, &:hover {
        background: darken($value, 10%);
        color: $white;
      }
    }
    .icon-#{$name} { @include iconColor($value); }
    .text-#{$name} { color: $value; }
  }
}

@mixin customAttribute($prop: null, $propName: null, $value: null, $valueName: null) {
  @if($propName == null) { $propName: $prop; }
  @if($valueName == null) { $valueName: $value; }
  :global {
    .#{$propName}-#{$valueName} { #{$prop}: $value; }
    .#{$propName}-t-#{$valueName},
    .#{$propName}-y-#{$valueName} {
      #{$prop}-top: $value;
    }
    .#{$propName}-r-#{$valueName},
    .#{$propName}-x-#{$valueName} {
      #{$prop}-right: $value;
    }
    .#{$propName}-b-#{$valueName},
    .#{$propName}-y-#{$valueName} {
      #{$prop}-bottom: $value;
    }
    .#{$propName}-l-#{$valueName},
    .#{$propName}-x-#{$valueName} {
      #{$prop}-left: $value;
    }
    @each $prefix in ("after", "before") {
      .#{$prefix}\:#{$propName}-#{$valueName}:#{$prefix} { #{$prop}: $value; }
      .#{$prefix}\:#{$propName}-t-#{$valueName}:#{$prefix},
      .#{$prefix}\:#{$propName}-y-#{$valueName}:#{$prefix} {
        #{$prop}-top: $value;
      }
      .#{$prefix}\:#{$propName}-r-#{$valueName}:#{$prefix},
      .#{$prefix}\:#{$propName}-x-#{$valueName}:#{$prefix} {
        #{$prop}-right: $value;
      }
      .#{$prefix}\:#{$propName}-b-#{$valueName}:#{$prefix},
      .#{$prefix}\:#{$propName}-y-#{$valueName}:#{$prefix} {
        #{$prop}-bottom: $value;
      }
      .#{$prefix}\:#{$propName}-l-#{$valueName}:#{$prefix},
      .#{$prefix}\:#{$propName}-x-#{$valueName}:#{$prefix} {
        #{$prop}-left: $value;
      }
    }
  }
}

@mixin iconColor($icon-color, $icon-hover-color: darken($icon-color, 10%)) {
  .fab, .fas, .svg-inline--fa {
    color: $icon-color;
    &.active, &:active, &:focus, &:hover { color: $icon-hover-color; }
  }
}

@mixin linkColor($link-color, $link-hover-color: darken($link-color, 10%)) {
  a, button, .btn.btn-link {
    @include iconColor($link-color, $link-hover-color);

    color: $link-color;
    &.active, &:active, &:focus, &:hover { color: $link-hover-color; }
  }
}
