input:not([type="checkbox"]):not([type="submit"]),
select,
textarea {
  background-color: $white;
  border: 1px solid $gray;
  border-radius: 5px;
  color: $black;
  display: block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 6px 12px;
  width: 100%;
  &:disabled {
    background-color: $gray;
    opacity: 1;
  }
}

label a { text-decoration: underline; }

:global {
  .bg-check, .checked\:bg-check {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
  }
}
