$black: #000000 !default;
$blue: #4D90FE !default;
$dark-gray: #6C757D !default;
$gray: #D3D3D3 !default;
$green: #42B755 !default;
$red: #DC3545 !default;
$white: #FFFFFF !default;
$yellow: #FFC107 !default;

$bg-navbar: $blue !default;
$btn-border-radius: 5px !default;
$gutter: 40px !default;
$link: $green !default;
$link-hover: $blue !default;
$dark-link: $white !default;
$dark-link-hover: $black !default;
