@use "sass:math";

:global {
  @tailwind base;
  @tailwind components;
  @tailwind utilities;
  @tailwind screens;
}

@import "./style/variables.scss";
@import "./style/mixins.scss";
@import "./style/default.scss";
@import "./style/action-text.scss";

@include customAttribute($prop: "border", $value: inherit);
@include customColor("black", $black);
@include customColor("blue", $blue);
@include customColor("dark-gray", $dark-gray);
@include customColor("gray", $gray);
@include customColor("green", $green);
@include customColor("red", $red);
@include customColor("yellow", $yellow);
